<template>
  <div class="news-details">
    <top-head></top-head>
    <share-bar title="推介详情"></share-bar>
    <van-skeleton title :row="50" :loading="loading">
      <div class="news-details-content">
        <div class="news-details-title">
          {{ details.title }}
        </div>
        <div class="news-details-time">
          {{ details.create_time }}
        </div>
        <div class="news-details-html" v-html="details.content"></div>
        <div class="relat-list">
          <div class="relat-title">
            相关阅读
          </div>
          <div
            class="relat-item fr"
            v-for="(item, index) in relatList"
            :key="index"
            @click="
              $router.replace({
                name: 'BrandProjectDetails',
                params: { id: item.id, title: '项目详情' }
              })
            "
          >
            <div class="relat-item-cover">
              <img :src="item.head_pic" alt="" />
            </div>
            <div class="relat-right-title-time">
              <div class="relat-right-title">{{ item.title }}</div>
              <div class="relat-right-time">{{ item.create_time }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-skeleton>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import TopHead from "@/components/TopHead";
import ShareBar from "@/components/ShareBar";
import BottomBar from "@/components/BottomBar";
import { fetchNewsDetails } from "@/api/news";
import { fetchBrandProject } from "@/api/benefit.js";
export default {
  data() {
    return {
      details: {},
      relatList: [],
      loading: true
    };
  },
  watch: {
    $route() {
      this.getDetails();
      this.getRelat();
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    pid() {
      return this.$route.params.pid;
    }
  },
  methods: {
    async getDetails() {
      const { result } = await fetchNewsDetails(this.id);
      this.details = result;
      this.loading = false;
    },
    async getRelat() {
      const { result } = await fetchBrandProject({
        cate_id: this.pid,
        pageSize: 4,
        pageNo: 1
      });
      const list = result.data.filter(item => item.id != this.id);
      this.relatList = list.slice(0, 3);
    }
  },
  mounted() {
    this.getDetails();
    this.getRelat();
  },
  components: {
    TopHead,
    BottomBar,
    ShareBar
  }
};
</script>

<style lang="less" scoped>
.news-details {
  .news-details-content {
    padding: 22vw 3vw;
    font-family: PingFang-SC-Bold;
    font-size: 5vw;
    color: #404040;
    .news-details-time {
      margin-top: 5vw;
      font-family: PingFang-SC-Medium;
      font-size: 3vw;
      color: #999999;
    }
    .news-details-html {
      margin-top: 7vw;
      font-family: PingFang-SC-Medium;
      font-size: 4vw;
      color: #404040;
    }
    .relat-list {
      margin-top: 7vw;
      .relat-title {
        font-family: PingFang-SC-Bold;
        font-size: 5vw;
        color: #404040;
      }
    }
  }
}

.relat-item {
  margin-top: 4vw;
}

.relat-item-cover {
  width: 31vw;
  height: 21vw;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
  }
}
.relat-right-title-time {
  margin-left: 2vw;
  .relat-right-title {
    font-family: PingFang-SC-Bold;
    font-size: 3vw;
    color: #404040;
  }
  .relat-right-time {
    margin-top: 3vw;
    font-family: PingFang-SC-Regular;
    font-size: 3vw;
    color: #8c8c8c;
  }
}
</style>

<style lang="less">
.news-details-html {
  img {
    max-width: 100%;
  }
}
</style>
